import React from 'react'
import Helpers from '../helpers'
import Layout from '../components/layout'
import Releases from '../components/releases';

export default () => {
  const releases = Helpers.getReleases('terminal-sound-system');
  
  return (
    <Layout title="Releases">
      <h1>
        Releases
      </h1>
      <Releases 
        items={releases}
        project={false}
      />
    </Layout>
  )
}
