module.exports = [
  require('./abm-we-have-become-oceans'),
  require('./abm-king-tide'),
  require('./ft-woven'), 
  require('./ft-unpermanent'), 
  require('./tss-the-endless-sea'),
  require('./siilt-002'),
  require('./tss-dust-songs'),
  require('./halo-gods-of-sound'),
  require('./tss-a-sun-spinning-backwards'),
  require('./siilt-schism-tapes'), 
  require('./tss-heavy-weather'),
  require('./tether-sines'), 
  require('./tss-constructing-towers'),
  require('./tss-compressor'),
  require('./tss-last-night-i-dreamed-of-armageddon'),
  require('./tss-black-note-ep'),
  require('./halo-body-of-light'),
  require('./abm-another-time'),
  require('./ass-vs-atomizer'), 
  require('./tss-rh-8sb'),
  require('./halo-guattari'),
  require('./abm-home'),
  require('./ass-deep-black-static'), 
  require('./abm-solar-winds-white-noise-antigravity'),
  require('./halo-degree-zero-point-of-implosion'),
  require('./abm-a-beautiful-machine'), 
  require('./tss-solaris'),
  require('./halo-massive-corporate-disease'),
  require('./antisound-five-wounds'), 
  require('./halo-subliminal-transmissions'),
]
